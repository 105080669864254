import React, { useEffect, useState } from "react";
import Modal from "components/shared/Modal";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { cancellationCallAPIData, clearcancellationCallAPIData } from 'redux/actions/callCancellation/callCancellation.actions';
import { selectors as callCancellationReducer } from 'redux/reducers/callCancellation/callCancellation_reducer';
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import './CancellationPopup.scss';

export const CancellationPopup = ({ lateCancellation, handleClosePopup, cancelData }) => {
    const dispatch = useDispatch();
    const queryParam = queryString.parse(location.search)?.interviewId;
    const disputeData = useSelector(callCancellationReducer?.getCancelCallsData);
    const [openModel, setOpenModel] = useState(true);
    const [getReason, setReason] = useState('');

    useEffect(() => {
        if (disputeData?.length > 0) {
            let callsQueryData = {
                requestId: queryParam,
                expertName: null,
                expertTypes: null,
                relevantCompanies: null,
                status: null,
                networkProviders: null,
            }
            // dispatch(clearDisputeCallAPIData());
            handleClosePopup();
            dispatch(getCallsAPIData(callsQueryData, 0));
        }
        dispatch(clearcancellationCallAPIData());
    }, [disputeData]);

    const handleCancel = () => {
        const queryData = {
            "requestId": cancelData?.requestId,
            "projectId": cancelData?.projectId,
            "id": cancelData?.id,
            "expertProfile": {
                "id": cancelData?.expertProfile?.id,
                "profileId": cancelData?.expertProfile?.profileId
            },
            "reason": getReason,
            "callStatus": "Cancelled"
        }
        dispatch((cancellationCallAPIData(queryData)));
    }

    const handleCancelReason = (event) => {
        setReason(event?.target?.value);
    }

    return (
        <div className="CancellationPopup__wrap">
            <Modal
                isOpen={openModel}
                className="cancellationpopup--modal">

                <div className="cancellationpopup__header">
                    {
                        lateCancellation === true ? 'Late Cancellation Confirmation' : 'Are you sure you want to cancel this meeting ?'
                    }
                </div>

                <div className="cancellationpopup__body">
                    {
                        lateCancellation === true &&
                        <div className="body--text">
                            Are you sure you want to cancel this call? Late cancellation (1-4 hours ahead of scheduled time) may result in charges
                        </div>
                    }
                    <div className="cancellationpopup--bodyText">
                        <div className="cancellationpopup--bodyText2">
                            <span className=""> If yes, please state your reason</span> <span className="optional">(optional)</span>
                        </div>
                        <input type='text'
                            className={`cancellation__input`} placeholder={'Reason'}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onChange={(e) => handleCancelReason(e)}
                        ></input>
                    </div>
                </div>

                <div className="cancellationpopup__footer">
                    <button className="cancel--btn" onClick={() => handleClosePopup()}>No, Close</button>
                    {/* <button className={getReason ? "approve--btn" : "approve--btn approve--btn-disabled"} disabled={getReason ? false : true} onClick={() => handleCancel()}>Yes, Cancel Meeting</button> */}
                    <button className={"approve--btn"} onClick={() => handleCancel()}>Yes, Cancel Meeting</button>
                </div>
            </Modal>
        </div>
    )
}