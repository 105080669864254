import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TargetIcon from 'assets/images/target.svg';
import PhoneIcon from "assets/images/phone-icon.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import EyeIcon from "assets/images/eye-icon.svg";
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatDate } from "utils/formatDate/formatDate";
import Image from "components/shared/Image";
import { upcomingCallsAPIData } from 'redux/actions/Upcomingcalls/Upcomingcalls.actions';
import { selectors as upcomingCallsSelector } from "redux/reducers/Upcomingcalls/Upcomingcalls_reducer";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import queryString from 'query-string';
import './UpcomingCalls.scss';

export const UpcomingCalls = ({ onViewAllClickHandler }) => {
    const queryData = queryString.parse(location.search)?.interviewId;
    const dispatch = useDispatch();
    const upcomingCallsData = useSelector(upcomingCallsSelector?.getUpcomingCallsResults);
    const upcomingCallsLoading = useSelector(upcomingCallsSelector?.getLoading);
    const [userTimezone, setUserTimezone] = useState('Asia/Kolkata');
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [lastPage, setLastPage] = useState(false);
    const [page, setPage] = useState(0);  // Start with page 0
    const [data, setData] = useState([]);

    const getUserTimeZone = async () => {
        try {
            const hrid = await getUserId();
            const resp = await getUserProfilePictures([hrid]);

            if (resp?.length > 0 && resp[0]?.hostOfficeLocation?.timezone) {
                setUserTimezone(resp[0].hostOfficeLocation.timezone);
            }
        } catch (error) {
            console.error('Error fetching user timezone:', error);
            setUserTimezone('Error fetching timezone');
        }
    };

    useEffect(() => {
        getUserTimeZone();
    }, []);

    useEffect(() => {
        setLoading(upcomingCallsLoading);
    }, [upcomingCallsLoading]);

    // This effect should only trigger when the page or the data changes
    useEffect(() => {
        // If new data is available, append it to the existing data
        if (upcomingCallsData?.upcomingCalls) {
            setData((prevData) => [...prevData, ...upcomingCallsData.upcomingCalls]);
        }
        if(upcomingCallsData?.upcomingCalls===undefined){
            setData([]);
        }

        // Set lastPage and hasMore based on the response data
        if (upcomingCallsData?.lastPage) {
            setLastPage(true);
        } else {
            setLastPage(false);
        }

        if (upcomingCallsData?.upcomingCalls?.length === 0) {
            setHasMore(false);
        }
    }, [upcomingCallsData]); // Trigger this effect when the API response changes

    // This will only trigger once on the first page load
    useEffect(() => {
        fetchData(page);
    }, []); // Empty dependency array ensures this runs only once on initial load

    const loadMoreData = () => {
        if (!lastPage && hasMore) {
            // Increment page number before fetching data
            setPage((prevPage) => {
                const nextPage = prevPage + 1;
                fetchData(nextPage); // Fetch data for the next page
                return nextPage;
            });
        }
    };

    // This fetches data based on the current page
    const fetchData = (page) => {
        dispatch(upcomingCallsAPIData(queryData, page, 2));  // Fetch data for the current page
    };

    const handleTimeDisplay = (userTime) => {
        const startDate = new Date(userTime);
        const options = {
            timeZone: userTimezone,
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        };

        const localStartDateTime = startDate?.toLocaleTimeString('en-US', options);
        return `${localStartDateTime} ${userTimezone}`;
    };

    return (
        <div className="upcomingcalls__wrap">
            <div className="upcomingcalls__headwrap">
                <div className="upcomingcalls__headdata">
                    <div className="upcomingcalls--head">Upcoming Calls</div>
                    {
                        loading === false &&
                        <div className="upcomingcalls--number">{upcomingCallsData?.totalCalls}</div>
                    }
                </div>
                {data?.length > 0 && loading === false && (
                    <div className="upcomingcalls__headviewall" onClick={() => onViewAllClickHandler({ target: { text: 'calls' } })}>
                        <Image src={EyeIcon} alt="EyeIcon" className="eye--icon" />
                        View All
                    </div>
                )}
            </div>

            <div className={data?.length === 0 && loading === false ? "upcomingcalls__body upcomingcalls__body--nores background--image" : "upcomingcalls__body upcomingcalls__body--res"} id="scrollable-container">
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    hasMore={hasMore}
                    next={loadMoreData}  // Trigger loading more data when scrolled to the bottom
                    scrollableTarget="scrollable-container"
                    className="commTemplates__infiniteScroll"
                >
                    {data?.length === 0 && loading === false && (
                        <div className="nocalls__data">
                            <div className="nocalls--head">No upcoming calls scheduled.</div>
                            <div className="nocalls--text">
                                As you progress with your project and gather data, your analytics dashboard will populate here, offering insights into your activities.
                            </div>
                        </div>
                    )}
                    {
                        data?.length === 0 && loading === true &&
                        <div className="calls__datawrapper">
                            <div className="calls__data calls__dataLoader" key={0}>
                                <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                            </div>
                            <div className="calls__data calls__dataLoader" key={1}>
                                <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                            </div>
                            <div className="calls__data calls__dataLoader" key={2}>
                                <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                            </div>
                        </div>
                    }
                    {
                        loading === false && data?.length > 0 &&
                        <div className="calls__datawrapper">
                            {data?.map((data, index) => (
                                <div className="calls__data" key={index}>
                                    <div className="calls__leftside">
                                        <Image src={PhoneIcon} alt="PhoneIcon" className="phone--icon" />
                                    </div>
                                    <div className="calls__rightside">
                                        <div className="calls__userwrap">
                                            <div className="calls__userinfo">
                                                <div className="user--name">{data?.expertName}</div>
                                                <div className="user--designation">{data?.title} - {data?.employerName} | via {data?.networkProvider}</div>
                                            </div>
                                        </div>
                                        <div className="call__date-type">
                                            <div className="call__dateclock">
                                                <Image src={ClockIcon} alt="ClockIcon" />
                                                <div className="call--date">
                                                    {formatDate(new Date(data?.scheduledSlotStartDateTime)?.toLocaleDateString())} -&nbsp;
                                                    {handleTimeDisplay(data?.scheduledSlotStartDateTime)}
                                                </div>
                                            </div>
                                            <div className="call--type">
                                                <Image src={TargetIcon} alt="TargetIcon" />
                                                {Array.isArray(data?.expertTypes) ? data?.expertTypes.join(', ') : data?.expertTypes}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </div>
    );
};
