import { REQUEST_ANALYTICS } from 'redux/constants';

//  People CCO actions
export const getRequestAnalyticsAPIData = (data) => ({
    type: REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_DATA,
    payload: { interviewRequestId: data.interviewID, type: data.type }
});

export const getRequestAnalyticsAPIDataPending = () => ({
    type: REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_PENDING
});

export const getRequestAnalyticsAPIDataSuccess = (payload) => ({
    type: REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_SUCCESS,
    payload
});

export const getRequestAnalyticsAPIDataFailure = (error) => ({
    type: REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});