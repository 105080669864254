import React, { PureComponent } from "react";
import history from "redux/history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, runSaga } from "redux/store";
import CONFIG from "config";
import { Route, Switch } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import ImplicitCallbackWrapper from "components/ImplicitCallback/ImplicitCallback";
import authService from "redux/auth";
import { toRelativeUrl } from '@okta/okta-auth-js';
import rootSaga from "redux/sagas";
import { getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { deleteCookie, getCookie } from "utils/cookies/cookies";
import { ExpertRequest } from "containers/ExpertRequest";
import { HomeScreen } from "containers/HomeScreen";
import { RequestDetailsWrapper } from "containers/RequestDetailsWrapper";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const {
  UI_URL: {
    HOME,
    REQUEST_FORM,
    REQUEST_DETAILS
  },
  OKTA: {
    REDIRECT_URL
  },
} = CONFIG;

export class App extends PureComponent {
  componentDidMount() {
    runSaga(rootSaga);
    const { location: { pathname } } = window;
    if (getFromLocalStorageAsJSON(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY)) {
      removeFromLocalStorage(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY);
    }
    if (getCookie(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY)) {
      deleteCookie(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY);
    }

    // The message simply indicates that execution of a resize event callback was 
    // deferred until after the next frame render to avoid an infinite loop. 
    // In fact, it is specifically codified in the Resize Observer Specification. 
    // The “undelivered notifications” are not lost, but simply delayed one render cycle.
    window.addEventListener('error', e => {
      if (e.message.includes('ResizeObserver')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }
  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render() {
    return (
      <>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Security oktaAuth={authService} restoreOriginalUri={this.restoreOriginalUri}>
              <Switch>
                <SecureRoute
                  exact
                  path={HOME}
                  component={HomeScreen}
                />
                {/* <SecureRoute
                  exact
                  path={HOME}
                  component={TestPage}
                /> */}
                <SecureRoute
                  exact
                  path={REQUEST_FORM(":view")}
                  component={ExpertRequest}
                />
                <SecureRoute
                  exact
                  path={REQUEST_DETAILS}
                  render={(props) => <RequestDetailsWrapper requestId={props.match.params.requestId} />}
                />
                <Route
                  path={REDIRECT_URL}
                  component={ImplicitCallbackWrapper}
                />
              </Switch>
            </Security>
          </ConnectedRouter>
        </Provider>
        <ToastContainer />
      </>
    );
  }
}