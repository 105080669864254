import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { UPCOMING_CALLS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getUpcomingCallsAPIDataPending,
    getUpcomingCallsAPIDataSuccess,
    getUpcomingCallsAPIDataFailure
} from "./Upcomingcalls.actions";

import { setError } from '../error/error.actions';


export function* getUpcomingCallsData(action) {
    try {
        const { API_URL: { UPCOMING_CALLS } } = CONFIG;
        const paramURL = UPCOMING_CALLS(action?.payload?.id, action?.payload?.page, action?.payload?.size);
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getUpcomingCallsAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getUpcomingCallsAPIDataSuccess(response));
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getUpcomingCallsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* upcomingCallsAPISaga() {
    try {
        yield takeLatest(UPCOMING_CALLS.API_UPCOMING_CALLS, getUpcomingCallsData);

    } catch (error) {
        yield (put(setError(error)));
    }
}