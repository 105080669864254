import { REQUEST_ANALYTICS } from "redux/constants";
export const name = "requestAnalytics";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    requestAnalyticsData: null,
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getRequestAnalyticsResults: (state) => state[name]?.requestAnalyticsData ? state[name]?.requestAnalyticsData : "",
    getUpdateDetailsResult: (state) => state[name]?.updateDetailsData ? state[name]?.updateDetailsData : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload?.data,
            };
        case REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.data?.length <= 0,
                requestAnalyticsData: action?.payload,
                updateDetailsData: action?.payload?.reqType ? action?.payload : state.updateDetailsData,
            };
        default:
            return state;
    }
}