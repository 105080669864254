import { DOWNLOAD_EXPERTS } from 'redux/constants';

//  People CCO actions
export const downloadExpertsAPIData = (id) => ({
    type: DOWNLOAD_EXPERTS.API_DOWNLOAD_EXPERTS,
    payload: {
        id: id
    }
});

export const getDownloadExpertsAPIDataPending = () => ({
    type: DOWNLOAD_EXPERTS.API_DOWNLOAD_EXPERTS_RESULTS_PENDING
});

export const getDownloadExpertsAPIDataSuccess = (payload) => ({
    type: DOWNLOAD_EXPERTS.API_DOWNLOAD_EXPERTS_RESULTS_SUCCESS,
    payload
});

export const getDownloadExpertsAPIDataFailure = (error) => ({
    type: DOWNLOAD_EXPERTS.API_DOWNLOAD_EXPERTS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearDownloadExpertsAPIData = () => ({
    type: DOWNLOAD_EXPERTS.API_CLEAR_DOWNLOAD_EXPERTS,
});