import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { DOWNLOAD_EXPERTS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getDownloadExpertsAPIDataPending,
    getDownloadExpertsAPIDataSuccess,
    getDownloadExpertsAPIDataFailure
} from "./DownloadExperts.actions";

import { setError } from '../error/error.actions';


export function* getDownloadExpertsData(action) {
    try {
        const { API_URL: { DOWNLOAD_EXPERTS } } = CONFIG;
        const paramURL = DOWNLOAD_EXPERTS(action?.payload?.id);
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getDownloadExpertsAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getDownloadExpertsAPIDataSuccess(response));
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getDownloadExpertsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* downloadExpertsAPISaga() {
    try {
        yield takeLatest(DOWNLOAD_EXPERTS.API_DOWNLOAD_EXPERTS, getDownloadExpertsData);

    } catch (error) {
        yield (put(setError(error)));
    }
}