import { NO_ACCESS } from 'redux/constants';

//  People CCO actions
export const noAccessAPIData = (data) => ({
    type: NO_ACCESS.API_NO_ACCESS,
    payload: {
        data: data,
    }
});

export const getNoAccessAPIDataPending = () => ({
    type: NO_ACCESS.API_NO_ACCESS_RESULTS_PENDING
});

export const getNoAccessAPIDataSuccess = (payload) => ({
    type: NO_ACCESS.API_NO_ACCESS_RESULTS_SUCCESS,
    payload
});

export const getNoAccessAPIDataFailure = (error) => ({
    type: NO_ACCESS.API_NO_ACCESS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearNoAccessAPIData = () => ({
    type: NO_ACCESS.API_CLEAR_NO_ACCESS,
});