import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestDetailsDescription } from "components/RequestDetailsDescription";
import { AnalyticsCards } from "components/AnalyticsCards";
import { UpcomingCalls } from "components/UpcomingCalls";
import { ExternalNetworks } from "components/ExternalNetworks";
import { clearUpcomingCallsAPIData } from 'redux/actions/Upcomingcalls/Upcomingcalls.actions';
import { CompletedCalls } from "components/CompletedCalls";
import { TeamMembers } from "components/TeamMembers";
import { getRequestAnalyticsAPIData } from "redux/actions/requestAnalytics/requestAnalytics.actions";
import { selectors as requestAnalyticsSelectors } from "redux/reducers/requestAnalytics/requestAnalytics_reducer";
import './RequestDetails.scss';
import queryString from 'query-string';

export const RequestDetails = ({ reqData, onViewAllClick, interviewId }) => {
    const dispatch = useDispatch();
    // const queryData = queryString.parse(location.search)?.interviewId;

    const requestAnalyticsData = useSelector(requestAnalyticsSelectors.getRequestAnalyticsResults);

    const [analyticsDetails, setAnalyticsDetails] = useState({
        "interviewsCompleted": {
            "totalCount": 0,
            "expertTypeCount": {}
        },
        "interviewsScheduled": {
            "totalCount": 0,
            "expertTypeCount": {}
        },
        "expertProfileReceived": {
            "totalCount": 0,
            "expertTypeCount": {}
        },
        "expertsInterviewed": {
            "totalCount": 0,
            "expertTypeCount": {}
        }
    });

    useEffect(() => {
        dispatch(clearUpcomingCallsAPIData());
        if (interviewId) {
            dispatch(getRequestAnalyticsAPIData({ interviewID: interviewId }));
        }
    }, []);

    useEffect(() => {
        if (interviewId) {
            dispatch(getRequestAnalyticsAPIData({ interviewID: interviewId }));
        }
    }, [interviewId]);

    useEffect(() => {
        if (requestAnalyticsData) {
            setAnalyticsDetails(requestAnalyticsData);
        }
    }, [requestAnalyticsData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="requestDetails__wrap">
            <div className="requestDetails__leftsection">
                <RequestDetailsDescription reqData={reqData} />
                <AnalyticsCards details={analyticsDetails} expertTypes={reqData.expertTypes} />
                {/* <CompletedCalls /> */}
                <TeamMembers data={reqData?.caseTeamMembers} templatePage={false} />
            </div>
            <div className="requestDetails__rightsection">
                <UpcomingCalls onViewAllClickHandler={onViewAllClick} />
                <ExternalNetworks extdata={reqData?.externalNetworks} />
            </div>
        </div>
    )
}