import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { REQUEST_ANALYTICS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getRequestAnalyticsAPIDataPending,
    getRequestAnalyticsAPIDataFailure,
    getRequestAnalyticsAPIDataSuccess
} from "./requestAnalytics.actions";

import { setError } from '../error/error.actions';


export function* getRequestAnalyticsAPIData(action) {
    try {
        const { API_URL: { GET_REQUEST_ANALYTICS } } = CONFIG;
        let paramURL = GET_REQUEST_ANALYTICS(action?.payload?.interviewRequestId);
            const axiosConfig = {
                method: 'GET',
                endpoint: paramURL,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
            };
            yield put(getRequestAnalyticsAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response && response.data) {
                yield put(getRequestAnalyticsAPIDataSuccess(response.data.detailedDataCount));
            }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getRequestAnalyticsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* requestAnalyticsAPISaga() {
    try {
        yield takeEvery(REQUEST_ANALYTICS.API_REQUEST_ANALYTICS_DATA, getRequestAnalyticsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}