import React, { useEffect, useRef } from "react";
import { Image } from 'semantic-ui-react';
import PinIcon from 'assets/images/pin.svg';
import GreenPinIcon from 'assets/images/addedpin.svg';
import PinBadge from 'assets/images/pinBadge.svg';
import './Notifications.scss';

export const Notifications = ({ notiData, closePopup }) => {
  const notificationsRef = useRef(null); // Ref for the notifications dropdown

  // Handle clicking outside of the notification dropdown to close it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       // If the click is outside the notifications container, close the popup
//       if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
//         closePopup(); // Close the popup if click is outside
//       }
//     };

//     // Add event listener to document to handle outside clicks
//     document.addEventListener('mousedown', handleClickOutside);

//     // Cleanup event listener when component is unmounted or updated
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [closePopup]);

  return (
    <div className="Notifications__wrap" ref={notificationsRef}>
      <div className="noti--head">Notifications</div>
      <div className="noti__body">
        <div className="noti__itemWrap">
          {
            notiData?.map((option, index) => (
              <div key={index} className={option?.isPinned ? "noti__item addBorder" : "noti__item"}>
                {option?.isPinned && <Image className="pin-badge" src={PinBadge} alt="PinBadge" />}
                <div className="noti__itemSub">
                  <div className="noti--id">Interview Request ID - {option?.reqId}</div>
                  <div className="noti--text">{option?.notiText}</div>
                </div>
                <div className="noti__itemSub2">
                  <div className="noti--notiTime">{option?.notiTime}</div>
                  <div className="noti--pinnedWrap">
                    <div className="noti--pin">
                      {option?.isPinned 
                        ? <Image className="pin-img" src={GreenPinIcon} alt="GreenPinIcon" /> 
                        : <Image className="pin-img" src={PinIcon} alt="PinIcon" />
                      }
                    </div>
                    <div className="noti--ok">{option?.isRead ? 'View List' : 'Ok'}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
