import { UPCOMING_CALLS } from 'redux/constants';

//  People CCO actions
export const upcomingCallsAPIData = (id, page, size) => ({
    type: UPCOMING_CALLS.API_UPCOMING_CALLS,
    payload: {
        id: id, page: page, size: size
    }
});

export const getUpcomingCallsAPIDataPending = () => ({
    type: UPCOMING_CALLS.API_UPCOMING_CALLS_RESULTS_PENDING
});

export const getUpcomingCallsAPIDataSuccess = (payload) => ({
    type: UPCOMING_CALLS.API_UPCOMING_CALLS_RESULTS_SUCCESS,
    payload
});

export const getUpcomingCallsAPIDataFailure = (error) => ({
    type: UPCOMING_CALLS.API_UPCOMING_CALLS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearUpcomingCallsAPIData = () => ({
    type: UPCOMING_CALLS.API_CLEAR_UPCOMING_CALLS,
});