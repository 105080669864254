import React, { useState, useEffect, useContext } from "react";
import startsWith from 'lodash.startswith';
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import validIcon from "assets/images/valid_icon.svg";
import { Image } from 'semantic-ui-react';
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { selectors as peopleSelector } from '../../redux/reducers/peopleresults/peopleresults_reducer';
import "./PhoneNumber.scss";
import { FormContext } from "context/form-context";

export const PhoneNumber = ({ handlePhoneValue, disablePhoneField, handleInvalidPhoneValue }) => {
    let getPeopleDataReducer = useSelector(peopleSelector?.getPeopleResults);
    const formContext = useContext(FormContext);

    const [userPhone, setUserPhone] = useState("");
    const [countryFlag, setCountryFlag] = useState("+1");
    const [isValid, setIsValid] = useState(false);
    const [disableField, setDisableField] = useState(disablePhoneField);

    useEffect(() => {
        if (formContext.fieldsData['phoneNo']?.length > 0) {
            setCountryFlag("+" + formContext.fieldsData['phoneNo'][0]);
            setUserPhone('+' + formContext.fieldsData['phoneNo'][0]?.concat(formContext.fieldsData['phoneNo'][1]));
            setIsValid(true);
        }
        else if (getPeopleDataReducer?.length > 0) {
            const phoneResponse = getPeopleDataReducer[0]?.phones || [];
            const resp = phoneResponse?.filter(item => item?.deviceType === 'Mobile' && item?.usage === 'Work');
            if (resp?.length > 0) {
                setUserPhone(resp[0]?.countryCode?.concat(resp[0]?.phoneNumber));
                setIsValid(true);

            }
        }
    }, [getPeopleDataReducer]);

    useEffect(() => {
        if (userPhone) {
            handleInvalidPhoneValue(isValid);
            if (isValid) {
                formContext.markFieldValid('phoneNo');
            } else {
                formContext.logInvalidFields('phoneNo');
            }
        }
    }, [isValid]);

    useEffect(() => {
        setDisableField(disablePhoneField);
    }, [disablePhoneField]);

    const handlePhoneChange = (phoneNumber, country) => {
        if (country.dialCode != phoneNumber) {
            setUserPhone(phoneNumber);
            setIsValid(isValidPhoneNumber(phoneNumber, (country.countryCode).toUpperCase()));
            if (isValidPhoneNumber(phoneNumber, (country.countryCode).toUpperCase())) {
                formContext.markFieldValid('phoneNo');
            } else {
                formContext.logInvalidFields('phoneNo');
            }
            handlePhoneValue([country.dialCode, phoneNumber?.slice(country.dialCode.length)], isValidPhoneNumber(phoneNumber, (country.countryCode).toUpperCase()));
        }
    };

    return (
        <div className="phoneInput__wrap">
            <PhoneInput
                className="number"
                countryCode={countryFlag}
                country="us"
                name="phoneNo"
                id="phoneNo"
                disableDropdown={disableField}
                disabled={disableField}
                value={userPhone}
                onChange={(phoneNumber, country) => handlePhoneChange(phoneNumber, country)}
                isValid={(inputNumber, country, countries) => {
                    return countries.some((country) => {
                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                    });
                }}
            />
            {isValid &&
                <Image src={validIcon} alt="validIcon" className="valid--img" />
            }
            {!isValid && userPhone &&
                <div className="error-txt width70">Please enter a valid phone number</div>
            }
        </div>
    );
}
