import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './DownloadExperts.scss';
import { useDispatch, useSelector } from 'react-redux';
import { downloadExpertsAPIData } from 'redux/actions/DownloadExperts/DownloadExperts.actions';
import { clearDownloadExpertsAPIData } from 'redux/actions/DownloadExperts/DownloadExperts.actions';
import { selectors as downloadExpertsSelector } from 'redux/reducers/DownloadExperts/DownloadExperts_reducer';
import Modal from "components/shared/Modal";
import queryString from 'query-string';

export const DownloadExperts = ({ handleClosePopup, openModel }) => {
    const dispatch = useDispatch();
    const downloadExpertsResults = useSelector(downloadExpertsSelector?.getDownloadExpertsResults);
    const loadingData = useSelector(downloadExpertsSelector?.getLoading);
    const queryData = queryString?.parse(location?.search)?.interviewId;

    const [downloadData, setDownloadData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDownloadExperts = () => {
        dispatch(downloadExpertsAPIData(queryData));
    }

    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData]);

    useEffect(() => {
        setDownloadData(downloadExpertsResults);
        const excelData = [];
        if (downloadExpertsResults?.length > 0) {

            downloadExpertsResults?.map((item, index) => {
                const screeningResponses = item?.screeningQuestions?.map(sq =>
                    `Question: ${sq.question} Answer: ${sq.answer}`
                ).join('\n');
                excelData?.push({
                    'Expert Name': item?.expertName,
                    'Expert Type/Module': item?.expertTypes?.join(', '),
                    'Relevant Company': item?.relevantExperience?.employerName,
                    'Relevant Position': item?.relevantExperience?.title,
                    'Country': item?.country,
                    'Expert Highlights/Biography': item?.biography,
                    'Screening Responses': screeningResponses,
                    'Network': item?.networkProvider,
                    'Cost/hr': item?.rate?.costPerHour,
                    'Cost/30 min': item?.rate?.costPer30Mins,
                    'Recommended tag': item?.recommended,
                    'Status': item?.profileStatus,
                    'Shortlisted': item?.shortlisted
                });
            });

            const workbook = XLSX.utils.book_new();

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Save the workbook to an Excel file
            XLSX.writeFile(workbook, `${queryData} Expert Profiles.xlsx`);
            dispatch(clearDownloadExpertsAPIData());
            handleClosePopup();
        }
    }, [downloadExpertsResults, loadingData])

    return (
        <>
            <Modal
                isOpen={openModel}
                className="downloadExperts--modal">

                <div className="downloadExperts__body">
                    <div className="downloadExperts--bodyText">
                        By downloading this file, you agree and must ensure that the data is not stored on your computer and is permanently deleted from the case team folder within 90 days after the case is closed.
                    </div>
                </div>

                <div className="downloadExperts__footer">
                    <button className="cancel--btn" onClick={() => handleClosePopup()}>No, Cancel</button>
                    <button className={loading ? "disabled--btn approve--btn" : "approve--btn"} onClick={() => openModel && handleDownloadExperts()}>Yes, Download</button>
                </div>
            </Modal>
        </>
    )
}